import React, { useEffect } from "react";
import "./App.css";

import Routes from "./Routes";

import { connect, useSelector } from "react-redux";
import WaitingLoader from "./ReusableComponents/Loader";
import '@stripe/stripe-js';

function App(props) {
  const { guestId,email,userId,moveInfo } = useSelector((state) => state.User);

  window.addEventListener(
    "resize",
    function () {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    true
  );

  useEffect(()=>{
    if(guestId||userId){
      const gTag = window.gtag || null;
      // eslint-disable-next-line no-unused-expressions
      gTag?.("config", "GTM-KQ98SR9H", {
        user_id: userId||guestId,
        email:email||"",
        is_guest:userId?false:true,
        move_info:moveInfo
      });
    }
  },[guestId,email,userId,moveInfo])

  return (
    <>
      {props.App.loading && <WaitingLoader />}
      <Routes />
    </>
  );
}
const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(App);
